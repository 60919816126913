<template>
<div class="main-content pages">
    <h1>Videos</h1>
    <div class="filtered-list-subhead">
        <font-awesome-icon class="mobile-filters-button" :icon="showFilters ? 'times-circle' : 'plus-circle'" @click="showFilters = !showFilters" />
        <div class="search-controls">
            <input type="search" v-model="filters.search_term" placeholder="Search..." @search="doSearch()">
            <div class="search-control-button" @click="doSearch()"><font-awesome-icon icon="search" class="search-icon"/></div>
        </div>
        <div></div>
    	<div class="page-controls">
    		<Pagination :paging="paging" @updatePage="goToPage" />
    	</div>
    </div>
    <div class="filtered-list">
        <div v-if="showFilters" class="filtered-list-sidebar">
            <div v-if="detail.content_id" class="filtered-list-sidebar-controls">
                <label>Actions...</label>
                <hr>
                <div class="button button-dark" @click="backToList()">Back To List</div>
            </div>
            <div v-else class="filtered-list-sidebar-controls">
                <label>Filters...</label>
                <hr>
                <label>Actions...</label>
                <div class="note">{{ filterMessage }}</div>
            </div>
        </div>
        <div v-if="!detail.video_id" class="filtered-list-content">
            <div v-if="list.length" class="filtered-list-grid">
                <div v-for="(item, index) in list" class="clickable-list-item" @click="getItemRoute(item.video_id)">
                    <img :src="item.preview_image">
                    <div>{{ item.description }}</div>
                    <div><label>Duration</label>{{ item.formatted_duration }}</div>
                    <div><label>Size</label>{{ item.width }} x {{ item.height }}</div>
                    <div><label>Host</label>{{ item.host }}</div>
                    <div><label>Created</label>{{ item.formatted_last_upload }}</div>
                    <div><label>Views</label>{{ item.views }}</div>
                </div>
            </div>
            <div v-else class="empty-content">NOTE: Video management program<br>still in development.</div>
        </div>
        <div v-else class="filtered-list-item">
            <div class="filtered-list-item-two-columns">
                <div>
                    <div class="section-heading">Video Details</div>
                    <div class="video-container">
                        <iframe class="video-player" :src="'https://player.vimeo.com/video/' + detail.video_key + '?byline=false&title=false&portrait=false'" frameborder="0" allow="fullscreen"></iframe>
                    </div>
                    <div>[ poster frame, duration, etc. ]</div>
                </div>
                <div>
                    <div class="section-heading">Video Content</div>
                    [ list of content items using this video ]
                </div>
            </div>

        </div>
    </div>

    <MessageModal v-if="message" :message="message" :loading="loading" :errorStatus="errorStatus" @dismiss="message = ''" />

    <iframe id="download" src="" style="display: none;"></iframe>

</div>
</template>
<script>
import Pagination from '@/components/Pagination.vue'
import LabelWithLoadingIcon from '@/components/LabelWithLoadingIcon.vue'
import MessageModal from '@/components/MessageModal.vue'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
export default {
    name: 'Login',
    components: {
        Pagination,
        LabelWithLoadingIcon,
        MessageModal
    },
    data() {
        return {
            loading: false,
            message: '',
            errorStatus: false,
            filterMessage: '',
            videoHosts: [],
            list: [],
            videoId: '',
            detail: {},
            paging: {},
            filters: {
                page: 1,
                search_term: '',
                video_host_id: ''
            },
            saving: false,
            saved: false,
            showFilters: false
        }
    },
    created() {
        this.getVideoHosts()
        this.videoId = this.$route.params.video_id
        if (this.videoId) {
            this.getItem(this.videoId)
        } else {
            this.getList()
        }
        this.showFilters = window.matchMedia("(min-width: 768px)").matches
    },
    watch: {
        '$route.params.video_id': function (videoId) {
            if (videoId) {
                this.videoId = videoId
                this.getItem(videoId)
            } else {
                this.detail = {}
                this.videoId = ''
                this.saved = false
                this.getList()
            }
        }
    },
    computed: {
        // mix the getters into computed with object spread operator
        ...mapGetters([
            'getUser',
            'getDownloadEndpoint'
        ]),
        hasFilters() {
            return this.filters.page > 1 || this.filters.search_term
        }
    },
	methods: {
        ...mapActions([
            'apiCall', // map `this.apiCall()` to `this.$store.dispatch('apiCall')`
        ]),
        getVideoHosts() {
		    this.message = ''
            this.errorStatus = false
    		this.apiCall({ destination: 'get_list_content_video_hosts' }).then(obj => {
                this.message = obj.message
    			if (obj.status == 'success') {
                    this.videoHosts = obj.video_hosts
                }
            })
        },
        localCaptializeWords(words) {
            return this.caplitalizeWords(words)
        },
        getList() {
            if (this.loading) { return }
		    this.message = this.filters.do_export ? 'Getting the export list...' : ''
            this.errorStatus = false
			this.loading = true
    		this.apiCall({ destination: 'get_list_content_videos', data: this.filters }).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
                    if (this.filters.do_export) {
        				// set hidden iframe src to the API's download URL
        				document.getElementById("download").src = this.getDownloadEndpoint + "?file_name=" + obj.file_name + "&output_file_name=" + obj.file_name
        				this.message = obj.total_items + ' items exported'
        				// var timer = setTimeout((function() {
        				// 	this.filterMessage = ''
        				// }.bind(this)),3000)
                        this.filters.do_export = false
                    } else {
                        this.detail = {}
    					this.list = obj.list
                        this.paging = obj.paging
                        window.scrollTo(0, 0);
                    }
    			} else {
					this.message = obj.message
                    this.errorStatus = true
				}
    		})
        },
        doExport() {
			this.message = 'Generating export file...'
            this.filters.do_export = true
            this.getList()
        },
        setFilter(which) {
            this.filters[which] = !this.filters[which]
            this.getList()
        },
        clearFilters() {
            this.filters = {
                page: 1,
                search_term: ''
            }
            this.getList()
        },
        doSearch() {
            this.getList()
        },
		goToPage(page) {
			this.filters.page = page
			this.getList()
		},
        getItemRoute(videoId) {
            this.$router.push('/videos/detail/' + videoId)
        },
        getItem(videoId) {
            if (this.loading) { return }
		    this.message = ''
            this.errorStatus = false
            if (videoId == 'new') {
    			this.detail = {
                    contact_id: 'new'
    			}
                return
            }
			this.loading = true
    		this.apiCall({ destination: 'get_item_content_video', data: { video_id: videoId } }).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
					this.detail = obj.detail
                    window.scrollTo(0, 0);
    			} else {
					this.message = obj.message
                    this.errorStatus = true
				}
    		})
        },
        newItem() {
            this.$router.push('/videos/detail/new')
        },
        setImage(image) {
            console.log('image', image)
            this.detail.image_id = image.image_id
            this.detail.image_url = image.image_url
        },
		saveItem() {

            console.log(this.detail)
            return

			if (this.saving) { return }
			this.message = ''
            this.errorStatus = false

            // TODO: validate inputs

			this.saving = true
    		this.apiCall({ destination: 'save_item_content_video', data: this.detail }).then(obj => {
				this.saving = false
    			if (obj.status == 'success') {
					this.detail.video_id = obj.video_id
					this.message = 'Video saved.'
                    setTimeout(() => { this.message = '' }, 2000)
					this.saved = true
                    if (this.detail.video_id != this.videoId) {
                        this.$router.push('/videos/detail/' + this.detail.video_id)
                    }
    			} else {
					this.message = obj.message
                    this.errorStatus = true
				}
    		})
		},
        backToList() {
            this.$router.push('/videos')
        }
    }
}
</script>
